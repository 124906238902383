import * as React from "react";
import { Trans } from 'gatsby-plugin-react-i18next';

const JoinCommunitySection = () => {
  return <>
    <section className="w-full relative z-20">
      <div className="absolute w-full h-full top-0 left-0 -z-10">
        <img className="w-full h-full object-cover" srcSet="/images/page-bg@2x.webp 2x, /images/page-bg.webp 1x"
          src="/images/page-bg.webp" width="1600" height="502" alt="page-bg" />
      </div>
      <div className="container h-full absolute top-0 left-0 right-0 bottom-0">
        <div className="layout-grid"><span></span><span></span><span></span><span></span></div>
      </div>
      <div className="container pt-110 lg:pt-185 sc-up-mb relative">
        <div className="text-center">
          <h1 className="heading-1 heading-1-2">
            <Trans>Join the Milkomeda Community</Trans>
          </h1>
        </div>
        <div className="space-custom"></div>
      </div>
      <div className="w-full -z-10"><img className="w-full" srcSet="/images/oval_BG@2x.webp 2x, /images/oval_BG.webp 1x"
        src="/images/oval_BG.webp" width="1600" height="235" alt="oval-top" /></div>
    </section>
  </>;
};

export default JoinCommunitySection;