import * as React from "react";
import { Trans, useI18next } from "gatsby-plugin-react-i18next";
import { externalUrlsByLocale } from "../utils/constants";
import { Locales } from "../../locales/languages";

const LearnSection = () => {
  const { language } = useI18next();

  return (
    <>
      <section className="relative">
        <div className="bg-blue-bg-dark">
          <div className="container z-10">
            <ul className="section-custom-top flex flex-wrap justify-center relative z-20">
              <li className="card-item-1 card card-b card-anim">
                <div className="box-item bg-blue-bg-dark py-36 px-40">
                  <div className="w-88 h-88 mx-auto">
                    <img
                      className="image-sd"
                      srcSet="/images/icons/learn@2x.webp 2x, /images/icons/learn.webp 1x"
                      src="/images/icons/learn.webp"
                      width="88"
                      height="88"
                      alt="learn"
                    />
                  </div>
                  <div className="mt-20 mb-auto">
                    <p className="text-40 font-semibold">
                      <Trans>Learn</Trans>
                    </p>
                    <p className="mt-10 text-menu-white leading-normal">
                      <Trans>Learn in depth about Milkomeda</Trans>
                    </p>
                  </div>
                  <div className="mt-30">
                    <a
                      href="https://medium.com/@milkomedafoundation"
                      target="_blank"
                      rel="noopener"
                      className="btn-blue"
                    >
                      <Trans>Visit our Blog</Trans>
                    </a>
                  </div>
                </div>
              </li>
              <li className="card-item-1 card card-b animation-delay-300 card-anim">
                <div className="box-item bg-blue-bg-dark py-36 px-40">
                  <div className="w-88 h-88 mx-auto">
                    <img
                      className="image-sd"
                      srcSet="/images/icons/build@2x.webp 2x, /images/icons/build.webp 1x"
                      src="/images/icons/build.webp"
                      width="88"
                      height="88"
                      alt="build"
                    />
                  </div>
                  <div className="mt-20 mb-auto">
                    <p className="text-40 font-semibold">
                      <Trans>Build</Trans>
                    </p>
                    <p className="mt-10 text-menu-white leading-normal">
                      <Trans>Develop once, deploy everywhere</Trans>
                    </p>
                  </div>
                  <div className="mt-30">
                    <a
                      href={externalUrlsByLocale.milkomedaDocDeveloperCardano[language as Locales]}
                      target="_blank"
                      rel="noopener"
                      className="btn-blue"
                    >
                      <Trans>Start building</Trans>
                    </a>
                  </div>
                </div>
              </li>
              <li className="card-item-1 card card-b animation-delay-600 card-anim">
                <div className="box-item bg-blue-bg-dark py-36 px-40">
                  <div className="w-88 h-88 mx-auto">
                    <img
                      className="image-sd"
                      srcSet="/images/icons/join@2x.webp 2x, /images/icons/join.webp 1x"
                      src="/images/icons/join.webp"
                      width="88"
                      height="88"
                      alt="join"
                    />
                  </div>
                  <div className="mt-20 mb-auto">
                    <p className="text-40 font-semibold">
                      <Trans>Join</Trans>
                    </p>
                    <p className="mt-10 text-menu-white leading-normal">
                      <Trans>Join our community, find us on social media</Trans>
                    </p>
                  </div>
                  <div className="mt-30">
                    <a href="#follow-us" className="btn-blue">
                      <Trans>Follow us</Trans>
                    </a>
                  </div>
                </div>
              </li>
              <li className="card-item-1 card card-b animation-delay-900 card-anim">
                <div className="box-item bg-blue-bg-dark py-36 px-40">
                  <div className="w-88 h-88 mx-auto">
                    <img
                      className="image-sd"
                      srcSet="/images/icons/chat@2x.webp 2x, /images/icons/chat.webp 1x"
                      src="/images/icons/chat.webp"
                      width="88"
                      height="88"
                      alt="chat"
                    />
                  </div>
                  <div className="mt-20 mb-auto">
                    <p className="text-40 font-semibold">
                      <Trans>Chat</Trans>
                    </p>
                    <p className="mt-10 text-menu-white leading-normal">
                      <Trans>Chat with us directly. Join us on Discord</Trans>
                    </p>
                  </div>
                  <div className="mt-30">
                    <a
                      href="https://discord.gg/6zY6sybPEk"
                      target="_blank"
                      rel="noopener"
                      className="btn-blue"
                    >
                      <Trans>Join Discord</Trans>
                    </a>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="w-full">
          <img
            className="w-full"
            srcSet="/images/oval_BG_down@2x.webp 2x, /images/oval_BG_down.webp 1x"
            src="/images/oval_BG_down.webp"
            width="1600"
            height="235"
            alt="oval-top"
          />
        </div>
        <div className="container h-full absolute top-0 left-0 right-0 bottom-0">
          <div className="layout-grid z-10">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </section>
    </>
  );
};

export default LearnSection;
