import * as React from "react";
import { Trans } from "gatsby-plugin-react-i18next";
import { Link } from "gatsby";

const FollowUsSection = () => {
  return (
    <>
      <section id="follow-us" className="pt-80 relative">
        <div className="container h-full absolute top-0 left-0 right-0 bottom-0">
          <div className="layout-grid z-10">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div className="container relative z-10">
          <h2 className="heading-2">
            <Trans>Follow us</Trans>
          </h2>
          <ul className="md:mt-80 -m-10 flex flex-wrap justify-center">
            <li className="card-item-1 card card-b">
              <a
                href="https://twitter.com/Milkomeda_com"
                target="_blank"
                rel="noopener"
                className="block box-item-1 p-25 md:p-40 md:pb-75"
              >
                <div className="w-48 h-48 mr-25 md:mx-auto">
                  <img
                    className="image-sd"
                    srcSet="/images/icons/twitter@2x.webp 2x, /images/icons/twitter.webp 1x"
                    src="/images/icons/twitter.webp"
                    width="48"
                    height="48"
                    alt="twitter"
                  />
                </div>
                <div className="md:mt-30">
                  <p className="text-32 font-semibold">Twitter</p>
                  <p className="mt-10 text-menu-white leading-normal">@Milkomeda_com</p>
                </div>
              </a>
            </li>
            <li className="card-item-1 card card-b animation-delay-300">
              <a
                href="https://discord.gg/6zY6sybPEk"
                target="_blank"
                rel="noopener"
                className="block box-item-1 p-25 md:p-40 md:pb-75"
              >
                <div className="w-48 h-48 mr-25 md:mx-auto">
                  <img
                    className="image-sd"
                    srcSet="/images/icons/discord@2x.webp 2x, /images/icons/discord.webp 1x"
                    src="/images/icons/discord.webp"
                    width="48"
                    height="48"
                    alt="discord"
                  />
                </div>
                <div className="md:mt-30">
                  <p className="text-32 font-semibold">Discord</p>
                </div>
              </a>
            </li>
            <li className="card-item-1 card card-b animation-delay-900">
              <a
                href="https://medium.com/@milkomedafoundation"
                target="_blank"
                rel="noopener"
                className="block box-item-1 p-25 md:p-40 md:pb-75"
              >
                <div className="w-48 h-48 mr-25 md:mx-auto">
                  <img
                    className="image-sd"
                    srcSet="/images/icons/medium@2x.webp 2x, /images/icons/medium.webp 1x"
                    src="/images/icons/medium.webp"
                    width="48"
                    height="48"
                    alt="medium"
                  />
                </div>
                <div className="md:mt-30">
                  <p className="text-32 font-semibold">Medium</p>
                  <p className="mt-10 text-menu-white leading-normal">@milkomedafoundation</p>
                </div>
              </a>
            </li>
            <li className="card-item-1 card card-b">
              <div className="box-item-1 p-25 md:p-40 md:pb-75">
                <div className="w-48 h-48 mr-25 md:mx-auto">
                  <img
                    className="image-sd"
                    srcSet="/images/icons/telegram@2x.webp 2x, /images/icons/telegram.webp 1x"
                    src="/images/icons/telegram.webp"
                    width="48"
                    height="48"
                    alt="telegram"
                  />
                </div>
                <div className="md:mt-30">
                  <p className="text-32 font-semibold">Telegram</p>
                  <p className="mt-10 text-menu-white leading-normal">coming soon</p>
                </div>
              </div>
            </li>

            <li className="card-item-1 card card-b">
              <div className="box-item-1 p-25 md:p-40 md:pb-75">
                <div className="w-48 h-48 mr-25 md:mx-auto">
                  <img
                    className="image-sd"
                    srcSet="/images/icons/github@2x.webp 2x, /images/icons/github.webp 1x"
                    src="/images/icons/github.webp"
                    width="48"
                    height="48"
                    alt="github"
                  />
                </div>
                <div className="md:mt-30">
                  <p className="text-32 font-semibold">Github</p>
                  <p className="mt-10 text-menu-white leading-normal">coming soon</p>
                </div>
              </div>
            </li>
            <li className="card-item-1 card card-b animation-delay-300">
              <div className="box-item-1 p-25 md:p-40 md:pb-75">
                <div className="w-48 h-48 mr-25 md:mx-auto">
                  <img
                    className="image-sd"
                    srcSet="/images/icons/reddit@2x.webp 2x, /images/icons/reddit.webp 1x"
                    src="/images/icons/reddit.webp"
                    width="48"
                    height="48"
                    alt="reddit"
                  />
                </div>
                <div className="md:mt-30">
                  <p className="text-32 font-semibold">Reddit</p>
                  <p className="mt-10 text-menu-white leading-normal">comming soon</p>
                </div>
              </div>
            </li>
            <li className="card-item-1 card card-b animation-delay-600">
              <div className="box-item-1 p-25 md:p-40 md:pb-75">
                <div className="w-48 h-48 mr-25 md:mx-auto">
                  <img
                    className="image-sd"
                    srcSet="/images/icons/yt@2x.webp 2x, /images/icons/yt.webp 1x"
                    src="/images/icons/yt.webp"
                    width="48"
                    height="48"
                    alt="youtube"
                  />
                </div>
                <div className="md:mt-30">
                  <p className="text-32 font-semibold">Youtube</p>
                  <p className="mt-10 text-menu-white leading-normal">coming soon</p>
                </div>
              </div>
            </li>
            <li className="card-item-1 card card-b animation-delay-900">
              <Link to="/contact" className="box-item-1 p-25 md:p-40 md:pb-75">
                <div className="w-48 h-48 mr-25 md:mx-auto">
                  <img
                    className="image-sd"
                    srcSet="/images/icons/email@2x.webp 2x, /images/icons/email.webp 1x"
                    src="/images/icons/email.webp"
                    width="48"
                    height="48"
                    alt="youtube"
                  />
                </div>
                <div className="md:mt-30">
                  <p className="text-32 font-semibold">Email</p>
                </div>
              </Link>
            </li>
          </ul>
        </div>
        <div className="space-custom"></div>
      </section>
    </>
  );
};

export default FollowUsSection;
