import * as React from "react";
import { Trans, useI18next } from "gatsby-plugin-react-i18next";
import { graphql, useStaticQuery } from "gatsby";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, A11y } from "swiper";
import { formatDateLocaleString } from "../utils/date";
import { Locales } from "../../locales/languages";

const LatestNewsSection = () => {
  const { language } = useI18next();

  const data = useStaticQuery<{
    milkomedafoundationMedium: {
      items: {
        guid: string;
        categories: string[];
        link: string;
        thumbnail: string;
        title: string;
        pubDate: string;
      }[];
    };
    milkomedaJaMedium: {
      items: {
        guid: string;
        categories: string[];
        link: string;
        thumbnail: string;
        title: string;
        pubDate: string;
      }[];
    };
  }>(graphql`
    query {
      milkomedafoundationMedium {
        items {
          guid
          link
          pubDate(formatString: "")
          categories
          title
          thumbnail
        }
      }
      milkomedaJaMedium {
        items {
          guid
          link
          pubDate(formatString: "")
          categories
          title
          thumbnail
        }
      }
    }
  `);

  const { items: ENpublications } = data.milkomedafoundationMedium;
  const { items: JApublications } = data.milkomedaJaMedium;

  const publications = language === Locales.english ? ENpublications : JApublications;

  return (
    <>
      <section id="features" className="sc-up-mt relative">
        <div className="space-custom"></div>
        <div className="container relative z-10">
          <h2 className="heading-2">
            <Trans>Latest news</Trans>
          </h2>
          <div className="relative mt-50 lg:mt-80 w-full">
            <Swiper
              spaceBetween={10}
              navigation={{
                nextEl: ".swiper-next-news",
                prevEl: ".swiper-prev-news",
              }}
              modules={[Navigation, A11y]}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 16,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 18,
                },
                992: {
                  slidesPerView: 4,
                  spaceBetween: 24,
                },
              }}
            >
              {publications.map((news, index) => (
                <SwiperSlide className="py-20 px-6" key={index}>
                  <li className="card card-b animation-delay-600">
                    <div className="w-full h-auto md:h-227 l:h-144 xl:h-200">
                      <img
                        className="image-cover rounded-2xl"
                        src={news.thumbnail}
                        width="306"
                        height="200"
                        alt={news.title}
                      />
                    </div>
                    <p className="text-16 md:text-20 mt-32 font-semibold">{news.title}</p>
                    <p className="mt-10 text-menu-white leading-normal">
                      {formatDateLocaleString(news.pubDate)}
                    </p>
                    <a
                      href={news.link}
                      target="_blank"
                      rel="noopener"
                      className="btn-blue-small mt-24"
                    >
                      <Trans>Read article</Trans>
                    </a>
                  </li>
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="swiper-prev-news transform rotate-180">
              <p className="sr-only">Go Prev</p>
              <img src="/images/icons/arrow_right.svg" alt="" />
            </div>
            <div className="swiper-next-news">
              <p className="sr-only">Go Next</p>
              <img src="/images/icons/arrow_right.svg" alt="" />
            </div>
          </div>
        </div>
        <div className="container h-full absolute top-0 left-0 right-0 bottom-0">
          <div className="layout-grid z-10">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </section>
    </>
  );
};

export default LatestNewsSection;
