import * as React from "react";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import Spinner from "./Spinner";

const SubscribeSection = () => {
  const { t } = useTranslation();
  const [email, setEmail] = React.useState("");
  const [consent, setConsent] = React.useState(false);
  const [status, setStatus] = React.useState<"idle" | "pending" | "success" | "rejected">("idle");

  const isFormValid = email !== "" && email.trim() !== "" && consent;

  const isLoading = status === "pending";
  const isError = status === "rejected";
  const isSuccess = status === "success";

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isFormValid) return;
    setStatus("pending");
    try {
      const response = await fetch("/api/mailjet-subscription", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });
      const data: any = await response.json();
      if (data?.ErrorInfo) {
        setStatus("rejected");
        return;
      }
      setStatus("success");
    } catch (err) {
      console.error(err);
      setStatus("rejected");
    }
  };

  const handleMailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleConsentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConsent(e.target.checked);
  };

  return (
    <>
      <section id="subscribe" className="sc-up-mt relative z-10">
        <div className="w-full -z-10">
          <img
            className="w-full"
            srcSet="/images/oval_BG@2x.webp 2x, /images/oval_BG.webp 1x"
            src="/images/oval_BG.webp"
            width="1600"
            height="235"
            alt="oval-top"
          />
        </div>
        <div className="container h-full absolute top-0 left-0 right-0 bottom-0">
          <div className="layout-grid z-10">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div className="bg-blue-bg-dark section-custom-top pb-0">
          <div className="container relative">
            <h2 className="heading-2">
              <Trans>SUBSCRIBE FOR UPDATES</Trans>
            </h2>
            <div className="flex flex-wrap -mx-12 align-center">
              <div className="w-full lg:w-2/4 px-12">
                <p className="why-item__text__title">
                  <Trans>Subscribe and never miss the latest updates about Milkomeda.</Trans>
                </p>
                <p className="mt-20 mb-20 md:mb-0 text-menu-white">
                  <Trans>
                    We will share only the latest updates, processes, plans and insights.
                  </Trans>
                </p>
                <form noValidate onSubmit={handleSubmit} className="md:mt-[40px]">
                  <label
                    htmlFor="consent"
                    className="flex items-start sm:items-center w-full cursor-pointer mb-16"
                  >
                    <input
                      id="consent"
                      type="checkbox"
                      className="form-checkbox form-checkbox--square hover:cursor-pointer relative top-[2px] sm:top-0"
                      value="Consent to Terms of Use"
                      onChange={handleConsentChange}
                      checked={consent}
                      required
                    />
                    <p className="ml-8 w-full text-menu-white text-12 hover:text-white">
                      I have read, understand and consent to the{" "}
                      <a href="/terms-of-use" target="_blank" className="underline">
                        <Trans>Terms of Use</Trans>
                      </a>
                      .
                    </p>
                  </label>
                  <div className="subscribe-form">
                    <label htmlFor="email" className="hidden">
                      <Trans>Email Address</Trans> <span className="asterisk">*</span>
                    </label>
                    <input
                      type="email"
                      name="EMAIL"
                      className="required email block py-20 px-30 w-full bg-blue-mail rounded-full placeholder:text-menu-white"
                      id="email"
                      placeholder={t("Your email address")}
                      onChange={handleMailChange}
                      required
                    />
                    <button
                      className="clear btn-big disabled:cursor-not-allowed"
                      type="submit"
                      disabled={!isFormValid}
                    >
                      {isLoading ? <Spinner /> : <Trans>Subscribe</Trans>}
                    </button>
                  </div>
                </form>
                {isSuccess && (
                  <div className="pl-10 w-full flex items-center justify-start text-white mt-16">
                    <img src="/images/icons/check-icon.svg" className="h-20 w-20 mr-6" alt="" />
                    <p>You have successfully subscribed to our newsletter!</p>
                  </div>
                )}
                {isError && (
                  <div className="pl-10 w-full flex items-center justify-start text-white mt-16">
                    <img src="/images/icons/error.svg" className="h-20 w-20 mr-6" alt="" />
                    <p>Something went wrong! Try again. </p>
                  </div>
                )}
              </div>
              <div className="w-full px-12 lg:w-2/4 self-center">
                <div className="astronaut">
                  <img
                    className="image-sd"
                    srcSet="/images/astronaut@2x.webp 2x, /images/astronaut.webp 1x"
                    src="/images/astronaut.webp"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SubscribeSection;
